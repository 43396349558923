import { createStore } from 'vuex'

export default createStore({
  state: {
    kinopioapikey: '',
  },
  mutations: {
    SET_APIKEY(state, value) {
      state.kinopioapikey = value
    },
  },
  actions: {},
  modules: {},
})
