<template>
  <div class="home">
    <Capture />
  </div>
</template>

<script>
// @ is an alias to /src
import Capture from '@/components/Capture.vue'

export default {
  name: 'Home',
  components: {
    Capture,
  },
}
</script>
