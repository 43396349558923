<template>
  <div class="hello">
    <h1>kinopio capture</h1>
    <p>space id</p>
    <input v-model="newCard.spaceId" />
    <p>card text</p>
    <textarea ref="cardcontent" v-model="newCard.name"></textarea>
    <p><button @click="captureCard()">Capture Card</button></p>

    <p>You will need to open browser console to get list of your space ids</p>
    <p><strong>BUG</strong> : Space must contain 1 card already</p>
  </div>
</template>

<script>
import EventService from '@/services/EventService.js'
export default {
  name: 'Capture',

  data: function () {
    return {
      //card_text is called name in kinopio API
      newCard: {
        name: '',
        spaceId: '',
      },
    }
  },
  props: {
    user_space: Object,
  },
  // ID for Adams (private) 'Inbox' Space - 2U_Hk3SgWfSTKANH_yxge
  mounted() {
    EventService.getYourSpaces()
      .then((response) => {
        //this.user_spaces = response.data
        console.log('your_spaces:', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },

  methods: {
    captureCard() {
      EventService.createCard(this.newCard)
      this.$refs['cardcontent'].value = ''
      // .then((response) => {
      //   //this.user_spaces = response.data
      //   // there is no reponse when creating a card I assume
      //   // so this probably does nothing
      //   console.log('card:', response.data)
      // })
      // .catch((error) => {
      //   console.log(error)
      // })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
