import axios from 'axios'
import store from '../store'

export default {
  getYourSpaces() {
    const apiClient = axios.create({
      baseURL: 'https://api.kinopio.club',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Cache-Control': 'must-revalidate, no-store, no-cache, private',
        'Content-Type': 'application/json',
        Authorization: store.state.kinopioapikey,
      },
    })
    return apiClient.get('/user/spaces')
  },

  createCard(data) {
    const apiClient = axios.create({
      baseURL: 'https://api.kinopio.club',
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Cache-Control': 'must-revalidate, no-store, no-cache, private',
        'Content-Type': 'application/json',
        Authorization: store.state.kinopioapikey,
      },
    })
    // I need to send the data return apiClient.post('/card' , data here)
    return apiClient.post('/card', data)
  },
}
